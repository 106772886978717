import * as React from "react";
import { FC } from "react";
import { Layout } from "../components/Layout";

const Header: FC<{ title: string }> = ({ title }) => (
	<h2 className="text-xl font-bold leading-7 sm:text-2xl sm:truncate">
		{title}
	</h2>
);

const IndexPage = () => {
	return (
		<Layout>
			<div className="pt-6 space-y-6">
				<h1 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate">
					Privacy Policy
				</h1>
				<div>
					<Header
						title="Does Headcacnon browser extension collect any personal
						data?"
					/>
					<p>
						The only data which might be considered personal is an
						email address. This piece of data is required for the
						application to work. Once it's typed in it does not
						leave your device until you issue a request to send
						selected fanfics to your device.
					</p>
					<p>
						Then on our servers it's only being used to send you an
						email using a third party email service provider. Once
						sending is complete, we no longer have any record of the
						provided email address.
					</p>
					<p>We do not collect any data besides email address.</p>
				</div>
				<div>
					<Header title="Do we share you data?" />
					<p>
						We only use the provided email address to deliver you
						the fanfics, so we process it by sending a request to
						send email through our email service provider. We do not
						share your data with anyone in any other form.
					</p>
				</div>
				<div>
					<Header title="What rights do you have?" />
					<p>
						Since we don't permanently store any of your data it's
						impossible to erase it (because there's nothing to
						erase). We also don't process any provided data after
						you receive your fanfics. At this moment it's impossible
						to stop the sending process after it's initialized, but
						your email processing stops right after this process is
						done.
					</p>
				</div>
				<div>
					<Header title="Cookies usage" />
					<p>
						We do not store any information using cookies both in
						the extension and on this page.
					</p>
				</div>
				<p className="text-xs">
					This policy was last updated on 2022-07-07
				</p>
			</div>
		</Layout>
	);
};

export default IndexPage;
